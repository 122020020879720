import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import './App.css';
import i18n from './i18n'
import Home from './components/Home'
import Main from './components/Main'
import FAQ from './components/FAQ'
import Login from './components/Login'
import SignUp from './components/SignUp'
import ErrorPage from './error-page';
import Alerts from './components/Alerts';
import Pricing from './components/Pricing';
import ResetPassword from './components/ResetPassword';
import CharacterDiningAtPlazaGardens from './components/Restaurants/CharacterDiningAtPlazaGardens';
import AubergeDeCendrillon from './components/Restaurants/AubergeDeCendrillon';
import BistrotChezRemy from './components/Restaurants/BistrotChezRemy';
import Restaurants from './components/Restaurants/Restaurants';
import PYMKitchen from './components/Restaurants/PYMKitchen';
import DowntownRestaurant from './components/Restaurants/DowntownRestaurant';
import CaptainJacks from './components/Restaurants/CaptainJacks';
import WaltsRestaurant from './components/Restaurants/WaltsRestaurant';
import RestaurantAgrabahCafe from './components/Restaurants/RestaurantAgrabahCafe';
import ManhattanRestaurant from './components/Restaurants/ManhattanRestaurant';
import LaGrangeBillyBobsSaloon from './components/Restaurants/LaGrangeBillyBobsSaloon';
import HuntersGrill from './components/Restaurants/HuntersGrill';
import PlazaGardens from './components/Restaurants/PlazaGardens';
import TheSteakhouse from './components/Restaurants/TheSteakhouse';
import RoyalBanquet from './components/Restaurants/RoyalBanquet';

function App() {

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'y3vq2eoq',
        custom_launcher_selector: '.open-intercom-chat',
      });
    }
    // return () => window.Intercom('shutdown')
  }, [])

  const lang = i18n.language === 'en' ? '' : `/${i18n.language}`

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Main/>,
      errorElement: <ErrorPage/>,
      children: [
        {
          errorElement: <ErrorPage/>,
          children: [
            {
              index: true,
              element: <Home/>,
            },
            {
              path: 'faq',
              element: <FAQ/>,
            },
            {
              path: 'login',
              element: <Login/>,
            },
            {
              path: 'signup',
              element: <SignUp/>,
            },
            {
              path: 'alerts',
              element: <Alerts/>,
            },
            {
              path: 'pricing',
              element: <Pricing/>,
              children: [
                {
                  path: ':referralCode',
                  element: <Pricing/>,
                },
              ],
            },
            {
              path: 'reset-password',
              element: <ResetPassword/>,
            },
            {
              path: 'restaurants',
              element: <Restaurants/>,
            },
            {
              path: 'restaurants/character-dining-at-plaza-gardens',
              element: <CharacterDiningAtPlazaGardens/>,
            },
            {
              path: 'restaurants/auberge-de-cendrillon',
              element: <AubergeDeCendrillon/>,
            },
            {
              path: 'restaurants/bistrot-chez-remy',
              element: <BistrotChezRemy/>,
            },
            {
              path: 'restaurants/pym-kitchen',
              element: <PYMKitchen/>,
            },
            {
              path: 'restaurants/downtown-restaurant',
              element: <DowntownRestaurant/>,
            },
            {
              path: 'restaurants/captain-jacks',
              element: <CaptainJacks/>,
            },
            {
              path: 'restaurants/hunters-grill',
              element: <HuntersGrill/>,
            },
            {
              path: 'restaurants/la-grange-billy-bobs',
              element: <LaGrangeBillyBobsSaloon/>,
            },
            {
              path: 'restaurants/manhattan-restaurant',
              element: <ManhattanRestaurant/>,
            },
            {
              path: 'restaurants/plaza-gardens-restaurant',
              element: <PlazaGardens/>,
            },
            {
              path: 'restaurants/restaurant-agrabah-cafe',
              element: <RestaurantAgrabahCafe/>,
            },
            {
              path: 'restaurants/royal-banquet',
              element: <RoyalBanquet/>,
            },
            {
              path: 'restaurants/the-steakhouse',
              element: <TheSteakhouse/>,
            },
            {
              path: 'restaurants/walts-restaurant',
              element: <WaltsRestaurant/>,
            }],
        },
      ],
    },

  ], {
    basename: lang,
  });

  const {t} = useTranslation();

  return (
    <div className="App">
      <Helmet htmlAttributes={{
        lang: i18n.language,
      }}>
        <title>{t('app-title', 'Get Disneyland Paris restaurant reservations')}</title>
        <meta
          name="description"
          content={t('app-description', 'Find last-minute and hard-to-find Disneyland Paris restaurant reservations. Get notified as soon as tables become available. Includes Auberge de Cendrillon, Downtown Restaurant, Plaza Gardens, Chez Remy and more!') as string}
        />
        <meta property="og:title"
              content={t('app-title', 'Get Disneyland Paris restaurant reservations') as string}/>
        <meta property="og:url" content={t('site-url', 'https://dlptables.com') as string}/>
        <meta property="og:description"
              content={t('app-description', 'Find last-minute and hard-to-find Disneyland Paris restaurant reservations. Get notified as soon as tables become available. Includes Auberge de Cendrillon, Downtown Restaurant, Plaza Gardens, Chez Remy and more!') as string}/>
      </Helmet>
      <RouterProvider router={router}/>
    </div>
  );
}

export default App;
