import React, { useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { PayPalButtons } from '@paypal/react-paypal-js'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useTranslation, Trans } from 'react-i18next';

import { orderSuccess, postCheckout, postUpgradeSubscription } from '../utils/api';
import { MainContext } from './Main';
import dinoDanceAnimation from '../images/dino-dance.gif'
import PhoneDialog from './PhoneDialog';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

// Product IDs
const MONTHLY_PAYPAL_SUB_ID = process.env.NODE_ENV === 'development' ? 'P-79S13812TX8454507MNZLDMA' : 'P-0SK89039331762823MNYVQIQ'
const MONTHLY_PAYPAL_SMS_SUB_ID = process.env.NODE_ENV === 'development' ? 'P-1GC65908UC996664NMNZLDVA' : 'P-5V054209M0898472LMNYVOOI'
const MONTHLY_STRIPE_SUB_ID = process.env.NODE_ENV === 'development' ? 'price_1LyHQwH6plxtKsERtzFKdMaf' : 'price_1LxGOxH6plxtKsER64pQzWY1'
const MONTHLY_STRIPE_SMS_SUB_ID = process.env.NODE_ENV === 'development' ? 'price_1M07QQH6plxtKsERF8b8GXDe' : 'price_1M07mEH6plxtKsERtO2tTEXh'


type productDisplayProps = {
  setSessionId: React.Dispatch<React.SetStateAction<string>>
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>
  setMessage: React.Dispatch<React.SetStateAction<string>>
}
const ProductDisplay = ({setSessionId, setSuccess, setMessage}: productDisplayProps) => {
  const context = useContext(MainContext)
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [phoneDialogOpen, setPhoneDialogOpen] = useState<boolean>(false)
  const [isUpgrade, setIsUpgrade] = useState<boolean>(false)
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState<boolean>(false)
  const [referralCode, setReferralCode] = useState<string | null>(useParams().referralCode || null)

  const checkoutClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.id === 'subscribe') {
      onCheckout('subscribe')
    } else if (event.currentTarget.id === 'sms-subscribe') {
      onCheckout('sms-subscribe')
    }
  }

  const onCheckout = async (checkOutType: string) => {
    // Stripe checkout flow
    let res = null
    // The below price_ids come from the Stripe dashboard
    if (checkOutType === 'subscribe') {
      res = await postCheckout(MONTHLY_STRIPE_SUB_ID, referralCode)
    } else if (checkOutType === 'sms-subscribe') {
      res = await postCheckout(MONTHLY_STRIPE_SMS_SUB_ID, referralCode)
    } else if (checkOutType === 'sms-upgrade') {
      setPhoneDialogOpen(false)
      res = await postUpgradeSubscription()
    }

    if (res?.ok) {
      const resData = await res.json()
      if (resData === 'User already a subscriber') {
        setMessage(
          t('already-a-subscriber', 'You are already a subscriber. Please refresh your browser or send us a message for help.') as string,
        );
      } else if (resData?.url) {
        window.location.replace(resData.url)
      } else if (resData === 'Upgrade successful') {
        await context.refreshUser()
        setMessage(
          t('upgrade-success', 'Upgrade successful! You will now start receiving SMS alerts') as string,
        )
      }
    } else {
      setMessage(
        t('something-went-wrong', 'Something went wrong. Please try again or send us a message for help.') as string,
      );
    }
  }

  const onSignUpClick = () => {
    navigate('/signup')
  }

  type paypalButtonProps = {
    subscriptionId: string
  }
  const PayPalButtonsComponent = ({subscriptionId}: paypalButtonProps) => {
    return (<PayPalButtons
      style={{
        'layout': 'horizontal',
        'tagline': false,
        'label': 'subscribe',
        'height': 35,
      }}
      createSubscription={(data, actions) => {
        return actions.subscription.create({
          'plan_id': subscriptionId,
          'custom_id': context.user.id,
        })
      }
      }
      onApprove={(data, actions) => {
        return orderSuccess(data.orderID, 'PAYPAL', referralCode, subscriptionId).then(() => {
          context.refreshUser()
          setSuccess(true);
          setSessionId('paypal')
        }) || Promise.resolve()
      }
      }
      onError={err => {
        console.error(err)
      }}
    />)
  }

  const handleSMSSubscribeClick = () => {
    setIsUpgrade(false)
    setPhoneDialogOpen(true);
  };

  const handleSMSUpgradeClick = () => {
    if (context.user.isPaypalSubscriber) {
      setContactUsDialogOpen(true)
    } else {
      setIsUpgrade(true)
      setPhoneDialogOpen(true);
    }
  }

  const ContactUsToUpgrade = () => {
    const handleClose = () => {
      setContactUsDialogOpen(false)
    }


    return (<Dialog
      open={contactUsDialogOpen}
      onClose={handleClose}>
      <DialogTitle>{t('contact-us', 'Contact us')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('contact-us-text', 'Please send us a message to upgrade your account to SMS alerts.')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="open-intercom-chat" onClick={handleClose} color="primary">Ok</Button>
      </DialogActions>
    </Dialog>)
  }

  return (
    <Styled>
      <div className="product">
        <div className="description">
          <Typography variant="h3" gutterBottom>{t('pricing', 'Pricing')}</Typography>
        </div>
        <div className="pricing-cards-container">

          <Card className="pricing-card">
            <Typography variant="h4" gutterBottom>{t('free', 'Free')}</Typography>
            <Typography variant="body1">
              <Trans>
                <span className="bold">1</span> email alert 😐
              </Trans>
            </Typography>
            {context?.user?.email ?
              <Button className="subscribe-button" disabled={!!context.user?.activeSubscriber}
                      variant="text">{t('active', 'Active')}</Button> :
              <Button className="subscribe-button" variant="contained"
                      onClick={onSignUpClick}>{t('sign-up', 'Sign up')}</Button>}
          </Card>

          <Card className="pricing-card">
            <Typography variant="h4" gutterBottom>{t('price-per-alert', '€4 per alert')}</Typography>
            <Typography variant="body1" gutterBottom>
              <Trans>
                <span className="bold">PRIORITY*</span> email alert 🚀
              </Trans>
            </Typography>

            <Link to={'/'}><Button className="subscribe-button"
                                   variant="contained">{t('create-alert', 'Create Alert')}</Button></Link>
          </Card>

          <Card className="pricing-card">
            <Typography variant="h4" gutterBottom>{t('10E-month', '€10 / month')}</Typography>
            <Typography variant="body1" gutterBottom>
              <Trans>
                Up to <span className="bold">20</span> active email alerts 😃
              </Trans>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Trans>
                <span className="bold">PRIORITY*</span> email alerts 🚀
              </Trans>
            </Typography>
            <Typography variant="caption">{t('cancel-anytime', 'Cancel anytime')}</Typography>
            {context?.user?.activeSubscriber && !context?.user?.activeSMSSubscriber ?
              <Button className="subscribe-button" variant="text">{t('subscribed', 'Subscribed!')} 😎</Button> :
              context?.user?.activeSMSSubscriber ?
                <Button className="subscribe-button" variant="text">{t('included', 'Included!')} 😎</Button> :
                !context?.user?.email ?
                  <Button className="subscribe-button" variant="contained"
                          onClick={onSignUpClick}>{t('sign-up', 'Sign up')}</Button> :
                  <><Button className="subscribe-button" id="subscribe" variant="contained"
                            onClick={checkoutClick}><CreditCardIcon
                    sx={{marginRight: '8px'}}/> {t('subscribe', 'Subscribe')}
                  </Button>
                    <div style={{width: '220px', marginTop: '10px'}}>
                      <PayPalButtonsComponent subscriptionId={MONTHLY_PAYPAL_SUB_ID}/>
                    </div>
                  </>}
          </Card>

          <Card className="pricing-card">
            <Typography variant="h4" gutterBottom>{t('14E-month', '€14 / month')}</Typography>
            <Typography variant="body1" gutterBottom>
              <Trans>
                Up to <span className="bold">20</span> active email and <span
                className="bold">SMS</span> alerts
              </Trans>
              🤩</Typography>
            <Typography variant="body1" gutterBottom>
              <Trans>
                <span className="bold">PRIORITY*</span> email alerts 🚀
              </Trans>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <Trans>
                <span className="bold">PRIORITY*</span> SMS alerts 💬
              </Trans>
            </Typography>
            <Typography variant="caption">{t('cancel-anytime', 'Cancel anytime')}</Typography>
            {context?.user?.activeSMSSubscriber ?
              <Button className="subscribe-button" variant="text">{t('subscribed', 'Subscribed!')} 😎</Button> :
              !context?.user?.email ?
                <Button className="subscribe-button" variant="contained"
                        onClick={onSignUpClick}>{t('sign-up', 'Sign up')}</Button> :
                context?.user?.activeSubscriber ?
                  <Button className="subscribe-button" variant="contained" onClick={handleSMSUpgradeClick}>
                    {t('upgrade', 'Upgrade')}</Button> :
                  <Button className="subscribe-button" variant="contained"
                          onClick={handleSMSSubscribeClick}><CreditCardIcon
                    sx={{marginRight: '8px'}}/> {t('subscribe', 'Subscribe')}</Button>
            }
          </Card>
        </div>
        <Typography variant="body2" style={{marginTop: '3rem'}}>
          <Trans>
            * Priority alerts arrive up to 60 minutes earlier than
            standard alerts. See <Link
            to="/faq">FAQ</Link> for more info.
          </Trans>
        </Typography>
        <Typography variant="body2"><Button className="open-intercom-chat questions-btn"
        >{t('questions', 'Questions?')}</Button></Typography>
      </div>
      <PhoneDialog open={phoneDialogOpen} setOpen={setPhoneDialogOpen} onCheckout={onCheckout}
                   PaypalButtons={PayPalButtonsComponent} MONTHLY_PAYPAL_SMS_SUB_ID={MONTHLY_PAYPAL_SMS_SUB_ID}
                   isUpgrade={isUpgrade}/>
      <ContactUsToUpgrade/>
    </Styled>
  )
};

const SuccessDisplay = () => {
  const {t} = useTranslation()

  return (
    <Styled>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>{t('sub-success', 'Subscription successful!')} 😎</h3>
          <Typography variant="body1">{t('thanks-dance', 'Thank you! Time for a happy dance.')}</Typography>
          <div className="dino-dance-container">
            <img className="dino-dance-gif" src={dinoDanceAnimation} alt="Dancing Dino"/>
          </div>
          <Typography variant="body1">
            {t('manage-sub-anytime', 'You can manage your subscription any time by clicking the user avatar in the top right.')}
          </Typography>
          <div className="success-continue-btn">
            <Link to={'/'}>
              <Button variant="contained">{t('continue', 'Continue')}</Button>
            </Link>
          </div>
        </div>
      </div>
    </Styled>
  );
};

type messagePropsType = {
  message: string
}
const Message = ({message}: messagePropsType) => (
  <section>
    <Typography variant="body1">{message}</Typography>
  </section>
);

export default function Pricing() {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const context = useContext(MainContext)
  const {t} = useTranslation()

  useEffect(() => {

    const onSuccess = async (newSessionId: string) => {
      // We don't need to provide a referral code for Stripe here because we provide it in the checkout session
      await orderSuccess(newSessionId, 'STRIPE', null, context.user.id)
      await context.refreshUser()
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      const newSessionId = query.get('session_id') || ''
      if (!success) {
        onSuccess(newSessionId)
        setSuccess(true);
      }
      setSessionId(newSessionId);
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        t('order-cancelled', 'Order canceled.') as string,
      );
    }

    if (query.get('limit_reached')) {
      setSuccess(false)
      setMessage(t('free-limit-reached', 'Free alert limit reached. Consider our paid plans to create more.') as string)
    }
  }, [sessionId, success, context, t]);

  if (!success && message === '') {
    return <ProductDisplay setSuccess={setSuccess} setSessionId={setSessionId} setMessage={setMessage}/>;
  } else if (success && sessionId !== '') {
    return <SuccessDisplay/>;
  } else {
    return (<>
      <Message message={message}/>
      <ProductDisplay setSuccess={setSuccess} setSessionId={setSessionId} setMessage={setMessage}/>
    </>);
  }
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;

    .bold {
        font-weight: bold;
    }

    .dino-dance-container {
        width: 100%;
        max-width: 400px;

        .dino-dance-gif {
            width: 100%;
        }
    }

    .success-continue-btn {
        margin-top: 20px;
    }

    .pricing-cards-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 2rem 0 1rem 0;
        gap: 2rem;

        .pricing-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 500px;
            min-height: 200px;
            padding: 1rem;

            .subscribe-button {
                margin: 2rem 0 0.5rem 0;
                width: 220px;
            }
        }
    }

    .questions-btn {
        margin-top: 2rem;
    }
`

