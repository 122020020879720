import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';


const FAQ = () => {
  const {t} = useTranslation()
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Styled>
      <div className="faqs">
        <Typography variant="h4"
                    className="faq-title">{t('frequently-asked-qs', 'Frequently Asked Questions')}</Typography>


        <Typography variant="h6" className={'faq-section-title'}>{t('general-questions', 'General')}</Typography>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography variant="body1" className="faq-question" id="tables-available">
              {t('when-tables-available', 'When do tables come available at Disneyland Paris restaurants?')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('tables-available-1', `If you have a Disneyland Paris Hotel + Tickets package and have your booking linked in the Disneyland Paris app, you are usually able to reserve tables for the dates of your stay up to 6+ months in advance. The exact time in advance appears to vary by restaurant 🤷.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('tables-available-2', 'If you do not have a Disneyland Paris hotel reservation, you will be able to book tables upto 60 days in advance. However, tables at the most in demand restaurants will likely be booked out months in advance and you may find no availability 60 days out.')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('tables-available-3', 'The good news is, from our data, we see many tables come available 1-2 weeks in advance. Likely this is due to cancellations before payments come due. Setting up an alert on dlptables.com is a great way to be notified as soon as they become available 😎.')}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3a'} onChange={handleChange('panel3a')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel3abh-content"
            id="panel3abh-header"
          >
            <Typography variant="body1" className="faq-question"
                        id="how-work">{t('how-app-works', 'How does the app work?')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('how-works-1', 'Our software constantly checks (24 hours a day) to see if the chosen restaurants have tables available for your selected date, time and party number. When it finds a table it sends an email to you with details of the restaurant, date and times found matching your criteria.')}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('how-works-2', 'When you receive the email, open the Disneyland Paris app, select "Book a table" and select the restaurant, date and number of guests. If no one has got there first, you should see the times from the email available to book 🤞.')}
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel-tips'} onChange={handleChange('panel-tips')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel-tipsabh-content"
            id="panel-tipsabh-header"
          >
            <Typography variant="body1" className="faq-question" id="how-work2">
              {t('table-tips', 'Tips to get hard to find tables')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul>
              <li><Typography variant="body1" paragraph>
                {t('table-tips-1', `Add alerts@dlptables.com to your email address book to speed up delivery of our alerts and avoid them
                  going to spam. We've found Yahoo mail and AOL to be the worst, often slowing down delivery of our
                  emails.`)}
              </Typography></li>
              <li><Typography variant="body1" paragraph>
                <Trans>
                  If you're worried about email delivery, sign up for <Link to={'/pricing'}>SMS alerts</Link>
                </Trans>
              </Typography></li>
              <li><Typography variant="body1" paragraph>
                {t('table-tips3', `If you have a larger group (over 6), set up alerts for 2 or more smaller tables. They come up more
                  often.`)}
              </Typography></li>
              <li><Typography variant="body1" paragraph>
                {t('table-tips4', `If you have a Disney hotel booking and once you have fully paid for your hotel, you can sometimes book character
                  dining as an add-on for dates that are not available through regular restaurant booking (if dates are
                  available).`)}
              </Typography></li>
              <li><Typography variant="body1" paragraph>
                {t('table-tips5', `Character dining is a wonderful experience where the Disney characters come to your tables during the
                  meal to talk/interact, take pictures and sign autographs. Character dining is currently only available
                  at 2 restaurants: Auberge de Cendrillon and Character Dining at Plaza Gardens Restaurant.`)}
              </Typography>
                <Typography variant="body1" paragraph>
                  {t('table-tips6', `Auberge de Cendrillon is open for lunch and dinner and is attended by various Disney princesses. The
                    exact princesses vary.`)}
                </Typography>
                <Typography variant="body1" paragraph>
                  {t('table-tips7', `Character Dining at Plaza Gardens Restaurant is open for breakfast and dinner and is attended by
                    Mickey/Minne and/or friends. The exact characters varies. Please note: Plaza Gardens Restaurant is
                    open for lunch, but there will not be any characters present.`)}
                </Typography></li>
            </ul>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
          >
            <Typography variant="body1" className="faq-question" id={'really-work'}>
              {t('really-work', `Does dlptables.com REALLY work?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('really-work-1', `It sure does! We find over 1,000 tables for our users each month 🤯`)}
            </Typography>
            <Typography variant="body1" paragraph>
              <Trans>
                You can see some of the quite lovely feedback our users have left{' '}
                <a href="https://www.buymeacoffee.com/dlptables" target="_blank"
                   rel="noopener noreferrer">here</a> and read a wonderful review from <a
                href="https://crepesandcastles.com/guide-to-dlp-tables/" target="_blank"
                rel="noopener noreferrer">crepesandcastles.com</a>
              </Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Typography variant="h6" className={'faq-section-title'}>{t('alerts', 'Alerts')}</Typography>

        <Accordion expanded={expanded === 'panel1a'} onChange={handleChange('panel1a')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1abh-content"
            id="panel1abh-header"
          >
            <Typography variant="body1" className="faq-question" id="hotel-notifications">
              {t('hotel-notifications', `I have a hotel booking, will you notify me of reservations greater than 60 days out?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('hotel-notifications-0', `No. Due to restrictions from Disney, we can't see if tables are available more than 60 days out from today.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('hotel-notifications-1', `That means if you have a Disney hotel reservation for a visit more than 60 days out, make sure to keep checking
                the app for tables. Once the date is within 60 days, we'll take over the checking and alert you when we find a table.`)}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="body1" className="faq-question" id="no-notifications">
              {t('no-notifications', `I set up an alert, but I haven't received any notifications.`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              <Trans>
                Currently, Disneyland Paris restaurants are bookable 2 months (60
                days) in advance for guests who are not staying in a Disneyland Paris hotel. For hotel guests the
                advance
                booking window varies.
              </Trans>
            </Typography>
            <Typography variant="body1" paragraph>
              {t('no-notifications-1', `If you're super duper organized and set up a reservation alert for further in advance, you won't
                start receiving notifications until your selected date is within the booking window.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('no-notifications-2', `If your reservation is within the booking window it could just be that no tables have come free.
                But don't worry! From our data we see many tables come available 1-2 weeks in advance 😅. Likely this is due to
                cancellations before payments come due.`)}
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography variant="body1" className="faq-question">
              {t('not-available', `I received an alert, but it is showing as not available in the Disneyland Paris app`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('not-available-2', `We notify you as soon as we find an available table, but it is
                possible someone books the table between you receiving our
                notification and attempting to book through the app 😭. Therefore to have the best chance to reserve your
                table, it's important to reserve the table via the app as soon as you receive our notification 🚀.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              <Trans>
                It's also important that you have <a
                href="https://www.disneylandparis.com/en-gb/faq/the-disney-account/how-to-link-a-ticket-or-hotel-to-my-disney-account/"
                target="_blank" rel="noopener noreferrer">linked your hotel booking
              </a> in the Disneyland Paris app if you're trying to book a table greater than 60 days out.
              </Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>


        <Accordion expanded={expanded === 'panel-delete-alert'} onChange={handleChange('panel-delete-alert')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel-delete-alertbh-content"
            id="panel-delete-alertbh-header"
          >
            <Typography variant="body1" className="faq-question">
              {t('delete-alert', `How do I delete an alert?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              <Trans>
                It is not possible to delete an alert, but you can disable it at <Link
                to="/alerts">dlptables.com/alerts</Link> by clicking the bell 🔔 icon.
              </Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel-more-alerts'} onChange={handleChange('panel-more-alerts')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel-more-alertsbh-content"
            id="panel-more-alertsbh-header"
          >
            <Typography variant="body1" className="faq-question">
              {t('get-more-alerts', `How do I get more alerts?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('get-more-alerts-1', `If you have used your free alert you can sign up for a membership to get up to 20 active priority alerts or pay
                per alert. You can also sign up for an SMS membership to receive SMS alerts.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              <Trans>
                See our <Link to="/pricing">pricing</Link> page for more details.
              </Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel-meal-times'} onChange={handleChange('panel-meal-times')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel-meal-times"
            id="panel-meal-times"
          >
            <Typography variant="body1" className="faq-question">
              {t('what-times', `What times are breakfast, lunch and dinner?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('what-times-1', `While it varies slightly by restaurant (e.g. some open at 12pm rather than 11.30am, some finish lunch
                earlier than 5.45pm etc.) the general times are:`)}
            </Typography>
            <ul>
              <li><Typography variant="body1" paragraph>
                {t('what-times-2', `Breakfast: 8.15am - 9.45am (Note: Character Dining at Plaza
                  Gardens Restaurant is currently the only DLP restaurant that takes reservations for
                  breakfast)`)}
              </Typography></li>
              <li><Typography variant="body1" paragraph>
                {t('what-times-3', `Lunch: 11.30am - 5.45pm`)}
              </Typography></li>
              <li><Typography variant="body1" paragraph>
                {t('what-times-4', `Dinner: 6pm onwards`)}
              </Typography></li>
            </ul>
          </AccordionDetails>
        </Accordion>


        <Typography variant="h6" className={'faq-section-title'}>{t('membership', 'Membership')}</Typography>

        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
          >
            <Typography variant="body1" className="faq-question" id="priority-alerts">
              {t('priority-alerts', `What are the 'Priority alerts' included with memberships?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('priority-alerts-1', `For users on the free tier we check for a free table every c.45-60 minutes.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('priority-alerts-2', `For users with a membership we check for a free table every few minutes.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('priority-alerts-3', `This means members can receive notifications up to 60 minutes before non-members,
                giving them a better chance to book a table that has come available.`)}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography variant="body1" className="faq-question" id={'cancel'}>
              {t('how-to-cancel', `How can I cancel my subscription?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('how-to-cancel-1', `When signed in, click the user avatar in the top right and select 'Manage Subscription'.`)}
            </Typography>
            <Typography variant="body1" paragraph>
              {t('how-to-cancel-2', `After cancelling you will not be charged again, but you will continue to receive your
                subscription benefits until the end of your current billing cycle.`)}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Typography variant="h6" className={'faq-section-title'}>
          {t('feedback', `Feedback`)}
        </Typography>

        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography variant="body1" className="faq-question" id={'it-worked'}>
              {t('it-worked', `Your app worked! How can I thank you?`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              {t('it-worked-1', `If dlptables.com helped secure a booking for you or your family's special
                trip, I'm very happy it helped! 🙌️`)}
            </Typography>

            <Typography variant="body1" paragraph>
              {t('it-worked-2', `If you'd like to show your appreciation you could:`)}
            </Typography>

            <ul>
              <li key={1}><Typography variant="body1"><a className="open-intercom-chat"
                                                         href="mailto:feedback@dlptables.com"
                                                         target="_blank"
                                                         rel="noopener noreferrer">
                {t('lmk-helped', `Let me know it helped`)}
              </a></Typography>
              </li>
              <li key={2}><Typography variant="body1">
                {t('lmk-helped-1', `Tell others about the site`)}
              </Typography></li>
            </ul>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography variant="body1" className="faq-question" id="bug">
              {t('bug-found', `I've found a bug, have a question, a suggestion or some feedback`)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" paragraph>
              <Trans>
                Nice! Please <a className="open-intercom-chat"
                                href="mailto:feedback@dlptables.com"
                                target="_blank"
                                rel="noopener noreferrer">
                let us know
              </a> 😀
              </Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
    .faq-title {
        margin-bottom: 3rem;
    }

    .faqs {

        .faq-section-title {
            margin-top: 2rem;
        }

        .faq-question {
            font-weight: bold;
        }
    }
`

export default FAQ
